import React from "react"
import { Box } from "@material-ui/core"

function PlayoffGame({ game }) {
  return game.homeTeamPoints > game.awayTeamPoints ? (
    <Box display="flex" p={1} m={1}>
      <Box p={1} width="50%" textAlign="center" bgcolor="success.main">
        {game.homeTeam.ownerName} ({game.homeTeamPoints})
      </Box>
      <Box p={1} width="50%" textAlign="center" bgcolor="error.main">
        {game.awayTeam.ownerName} ({game.awayTeamPoints})
      </Box>
    </Box>
  ) : (
    <Box display="flex" p={1} m={1}>
      <Box p={1} width="50%" textAlign="center" bgcolor="success.main">
        {game.awayTeam.ownerName} ({game.awayTeamPoints})
      </Box>
      <Box p={1} width="50%" textAlign="center" bgcolor="error.main">
        {game.homeTeam.ownerName} ({game.homeTeamPoints})
      </Box>
    </Box>
  )
}

export default function Playoff({ games }) {
  return games
    .sort((a, b) => a.week > b.week)
    .map(game => (
      <PlayoffGame
        key={`${game.homeTeam.ownerName}${game.awayTeam.ownerName}`}
        game={game}
      />
    ))
}
