import React from "react"
import OwnerStatsTable from "./owner-stats-table"
import Playoff from "./playoff"
import Layout from "../components/layout"
import SeasonList from "./season-list"
import { Box, Paper, Typography } from "@material-ui/core"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

export default function Season({ pageContext }) {
  const { teams, season, bsb, playoffs } = pageContext
  const playoffWeeks = [
    ...new Set(playoffs.map(playoff => playoff.week).sort()),
  ]
  const championshipWeek = playoffWeeks[playoffWeeks.length - 1]
  const divisions = [...new Set(teams.map(team => team.divisionName).sort())]
  const [state, setState] = React.useState({
    displayDivisions: false,
  })

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  return (
    <Layout>
      <SeasonList active={season} />
      <h2>Regular Season</h2>
      {season !== 2011 && (
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={state.checkedB}
                onChange={handleChange}
                name="displayDivisions"
                color="primary"
              />
            }
            label="Divisions"
          />
        </FormGroup>
      )}
      {state.displayDivisions ? (
        divisions.map(division => (
          <Box key={division} component="div">
            <h3 key={division}>{division}</h3>
            <OwnerStatsTable
              items={teams.filter(team => team.divisionName === division)}
              showOwnerName={true}
            />
          </Box>
        ))
      ) : (
        <Box component="div">
          <OwnerStatsTable items={teams} showOwnerName={true} />
        </Box>
      )}
      <h2>Playoffs</h2>
      {playoffWeeks.map(week => (
        <Paper key={`${week}Paper`}>
          <Typography align="center" variant="h6">
            {week === championshipWeek ? `Championship` : `Week ${week}`}
          </Typography>
          <Playoff
            key={week}
            games={playoffs.filter(game => game.week === week)}
          />
        </Paper>
      ))}
      <h2>Board Shorts Bowl</h2>
      <Paper>
        <Playoff games={bsb} />
      </Paper>
    </Layout>
  )
}
